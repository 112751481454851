<script setup lang="ts">
import { NButton, NInput } from 'naive-ui'
import { ref } from 'vue'

import { editor } from '@/data/editor'
import type { RestrictWith, Task } from '@/types'
import { makeProp } from '@/utils/property'

type TaskType = Partial<RestrictWith<Task, 'recognition', 'Custom'>>

const props = defineProps<{
  task: TaskType
}>()

function make<K extends keyof TaskType>(key: K) {
  return makeProp(() => props.task, key)
}

const custom_recognition = make('custom_recognition')
const custom_recognition_param = make('custom_recognition_param')

const cache = ref<string | null>(null)
</script>

<template>
  <template v-if="!editor.hideUnset || 'custom_recognition' in task">
    <n-button @click="custom_recognition = null"> custom_recognition </n-button>
    <n-input v-model:value="custom_recognition" placeholder=""></n-input>
  </template>
  <template v-if="!editor.hideUnset || 'custom_recognition_param' in task">
    <n-button @click="custom_recognition_param = null"> custom_recognition_param </n-button>
    <n-input
      :value="cache ?? JSON.stringify(custom_recognition_param, null, 2)"
      @update:value="
        v => {
          try {
            const o = JSON.parse(v)
            cache = null
            custom_recognition_param = o
          } catch (_) {
            cache = v
          }
        }
      "
      placeholder=""
    ></n-input>
  </template>
</template>
